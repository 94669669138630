<template>
  <footer class="">
    <div class="expbtn">
      <i
          class="fa fa-angle-down" title="Expand/Collapse Footer"></i>
    </div>
    <div class="ifooter " id="demo">

      <div class="f1 menuList">
        <div class="container">
          <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 no-mobile">
              <a class="fhead" href="#">About GST</a>
              <ul>
                <li><a href="#">GST Council Structure</a></li>
                <li><a href="#">GST History</a></li>
              </ul>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 no-mobile">
              <a class="fhead"
                 href="#">Website Policies</a>
              <ul>
                <li><a href="#">Website Policy</a></li>
                <li><a href="#">Terms and Conditions</a></li>
                <li><a href="#">Hyperlink Policy</a></li>
                <li><a href="#">Disclaimer</a></li>
              </ul>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 no-mobile">
              <a class="fhead"
                 href="#">Related Sites</a>
              <ul>
                <li><a data-popup="true"
                       href="#">Central Board
                  of Indirect Taxes and Customs</a>
                </li>
                <li><a
                    href="#">State
                  Tax Websites</a>
                </li>
                <li><a data-popup="true"
                >National
                  Portal</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-2 col-xs-12 help no-mobile">
              <a class="fhead"
                 target="_self"
                 href="#">Help and
                Taxpayer Facilities</a>
              <ul>
                <li><a
                    href="#">System Requirements</a></li>
                <li><a
                    href="#">GST Knowledge Portal</a></li>
                <li><a data-popup="true" href="#"
                       target="_blank">GST
                  Media</a>
                </li>
                <!---->
                <!---->
                <!---->
                <li>
                  <a
                      target="_self"
                      href="#">Site Map</a>
                </li>
                <!---->
                <li><a
                    target="_self"
                    href="#">Grievance Nodal
                  Officers </a>
                </li>
                <li><a data-popup="true"
                       target="_blank"
                       href="#">Free Accounting and Billing
                  Services</a>
                </li>
                <li><a data-popup="true"
                       target="_blank"
                       href="#">GST Suvidha Providers</a></li>
              </ul>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 cont no-mobile scl">
              <a class="fhead"
                 href="#">Contact Us</a>
              <ul>
                <li>
                  <span class="contact">Help Desk Number: <br>1800-103-4786</span>
                </li>
                <!-- <li>
                   <a data-popup="true"  ></a>
                   </li> -->
                <li>
                  <span class="contact">Log/Track Your Issue:<br></span>
                  <a data-popup="true"
                     href="#"
                     title="Grievance Redressal Portal for GST"
                     target="_blank"
                  >Grievance
                    Redressal Portal for GST</a>
                </li>
                <li class="social">
                  <a data-popup="true"
                     href="#"
                     title="Facebook"><span>
                    <img
                        src="//static.gst.gov.in/uiassets/images/Facebook.svg"/></span></a>
                  <a data-popup="true" href="#"
                     title="Youtube"><span>
                    <img
                        src="//static.gst.gov.in/uiassets/images/Youtube.svg"/></span></a>
                  <a data-popup="true" href="#"
                  ><span>
                    <img
                        src="//static.gst.gov.in/uiassets/images/X.svg"/></span></a>
                  <a data-popup="true"
                     href="#"
                     title="Linkedin"><span>
                    <img
                        src="//static.gst.gov.in/uiassets/images/Linkedin.svg"/></span></a>
                </li>
                <!---->
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="f2">
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <p>©&nbsp;2024-25&nbsp;Goods and Services Tax Network</p>
              <p>Site Last Updated on 26-06-2024</p>
              <p>Designed &amp; Developed by GSTN</p>
            </div>
          </div>
        </div>
      </div>
      <div class="f3">
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <p class="site">Site best viewed at 1024 x 768 resolution in Microsoft Edge, Google Chrome
                49+, Firefox 45+ and Safari 6+
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
    name: 'Footer'
};
</script>

<style>
.disabled {
  cursor: not-allowed !important;
  opacity: 0.6;
}

.menuList .disabled:active {
  pointer-events: none;
}

.menuList .disabledanchor {
  cursor: not-allowed;
  pointer-events: none;
}

</style>
