<template>
    <div class="modal fade in" aria-hidden="false" style="display: block;" v-if="showModal">
        <div class="modal-dialog sweet">
            <form class="ng-pristine ng-invalid ng-invalid-required ng-valid-minlength ng-valid-maxlength" role="form"
                  name="gstinAuth" data-ng-submit="validateGstin(checkGstin)" autocomplete="off" novalidate="" @submit.prevent="verifyitem">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" @click="closeModal" data-dismiss="modal" data-ng-click="closeMod()">×</button>
                        <h4 class="modal-title text-center" data-ng-bind="trans.LBL_ENTER_GSTN_VRF">GSTIN/Other Id
                            Verification </h4>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-6 col-sm-offset-3 col-xs-12">
                                <label class="form-conf-label" data-ng-bind="trans.LBL_ENTER_RE_ENTR">Re-Enter
                                    GSTIN/Other Id for Challan Generation</label>
                            </div>
                            <div class="col-sm-6 col-sm-offset-3 col-xs-12"
                                 data-ng-class="{ 'has-error': err || otpErr || gstnErr}">
                                <input data-ng-model="checkGstin.gstincomp" type="text" v-model="gstId"
                                       class="form-control textbox ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-minlength ng-valid-maxlength"
                                       data-ng-paste="$event.preventDefault();" data-ng-minlength="15" maxlength="15"
                                       name="gstin" required="">
                                <!---->

                                <!---->
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" title="Cancel" @click="closeModal()"
                                data-ng-bind="trans.LBL_CANCEL">Cancel
                        </button>
                        <button type="submit" class="btn btn-primary" title="Proceed" data-ng-bind="trans.LBL_PROC">
                            Proceed
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GstVerificationModal',
    props: {
        gstno: {

        }
    },
    mounted () {
        console.log('gstNo', this.gstno);
    },
    data () {
        return {
            gstId: '',
            showModal: false
        };
    },
    methods: {
        closeModal () {
            this.$emit('close');
        },
        verifyitem () {
            if (this.gstId === this.gstno) {
                this.$emit('success');
                this.showModal = false;
            } else {
                this.$emit('close');
            }
        }
    }
};
</script>

<style scoped>

</style>
