<template>
    <div class="rowtp-mar" data-ng-if="confHide">
        <div class="row">
            <hr>

            <div class="col-sm-3 col-xs-12">
                <span data-ng-bind="trans.LBL_SELCT_EPYMD" class="m-cir">Select Mode of E-Payment</span>
                <ul class="dev-tab">
                    <li id="pb" value="PB">
                        <a @click="selectPreferredBanks('PB')">
                            <i class="fa fa-credit-card mr-1" aria-hidden="true"></i>
                            <span data-ng-bind="'Preferred Banks'">Preferred Banks</span>
                            <i id="pbi" class="fa fa-check fa-wd pull-right"
                               v-if="paymentDetails.payment_mode ==='preferred banks'"></i>
                        </a>
                    </li>
                    <li id="nb" value="NB"><a @click.prevent="selectPreferredBanks('NB')"><i class="fa fa-credit-card mr-1"
                                                                                     aria-hidden="true"></i>
                        <span data-ng-bind="trans.LBL_NB">Net Banking</span>
                        <i id="" class="fa fa-check fa-wd pull-right"
                           v-if="paymentDetails.payment_mode ==='net banking'"></i></a>
                    </li>
                    <!--Neelam Un Commented/UnMasked -->
                    <!---->
                    <li id="upi" value="UPI" data-ng-if="isReadystateUPI">
                        <a @click="selectPreferredBanks('BU')" data-ng-click="UPI()"><i
                                class="fa fa-credit-card mr-1" aria-hidden="true"></i> <span data-ng-bind="trans.LBL_UPI">BHIM UPI</span>
                            <i class="fa fa-check fa-wd pull-right"
                               v-if="paymentDetails.payment_mode ==='bhim upi'"></i>
                        </a>
                    </li><!---->
                    <!---->
                    <li id="cc" value="CC" data-ng-if="isReadyStateCCDC"><a @click="selectPreferredBanks('CDB')">
                        <i class="fa fa-money mr-1" aria-hidden="true"></i>
                        <span data-ng-bind="trans.LBL_CD">Credit/Debit Card</span>
                        <i class="fa fa-check fa-wd pull-right "
                           v-if="paymentDetails.payment_mode ==='credit or debit card'"></i>
                    </a>
                    </li><!---->
                </ul>
            </div>
            <div class="col-sm-9 col-xs-12 rowtp-mar " v-if="paymentBanks">
                <div class="col-xs-12 com-sm-1">
                    <div class="col-xs-3">
                        <b class="" v-if="paymentDetails.payment_mode === 'preferred banks'">Preferred Bank</b>
                        <b class="" v-else-if="paymentDetails.payment_mode === 'net banking'">Net Banking</b>
                        <b class="" v-else-if="paymentDetails.payment_mode === 'bhim upi'">BHIM UPI</b>
                        <b class="" v-else-if="paymentDetails.payment_mode === 'credit or debit card'">Credit/Debit Card</b>
                    </div>
                    <div class="col-xs-8">
                        <!---->
                        <!---->
                    </div>
                    <div class="col-xs-1">
                                        <span style="float:right">
                                            <a type="button">
                                                <i class="fa fa-trash"></i>
                                            </a>
                                        </span>
                    </div>
                    <p></p>
                </div>
                <br>
                <br>
                <div>
                    <ul class="no-list">
                        <!---->
                        <!---->
                        <div v-for="(item,index) in paymentBanks" :key="index">
                            <li data-ng-repeat="pb in preferredBanks">
                                <input class="form-control ng-pristine ng-untouched ng-valid ng-empty"
                                       data-ng-model="challanData.bank_cd" name="bank_cd" :value=item.id
                                       @input="emitSuccess(item.id)"
                                       data-ng-change="chkDis()" type="radio" v-model="paymentDetails.bank">
                                <label>{{item.name}}</label>
                            </li><!---->
                        </div>

                        <!--                        <li data-ng-repeat="pb in preferredBanks">
                                                    <input class="form-control ng-pristine ng-untouched ng-valid ng-empty" id="SBIN"
                                                           data-ng-model="challanData.bank_cd" name="bank_cd" value="SBIN"
                                                           data-ng-change="chkDis()" type="radio">
                                                    <label for="SBIN">STATE BANK OF INDIA</label>
                                                </li>&lt;!&ndash;&ndash;&gt;
                                                <li data-ng-repeat="pb in preferredBanks">
                                                    <input class="form-control ng-pristine ng-untouched ng-valid ng-empty" id="KVBL"
                                                           data-ng-model="challanData.bank_cd" name="bank_cd" value="KVBL"
                                                           data-ng-change="chkDis()" type="radio">
                                                    <label for="KVBL">KARUR VYSYA BANK</label>
                                                </li>&lt;!&ndash;&ndash;&gt;
                                                <li data-ng-repeat="pb in preferredBanks">
                                                    <input class="form-control ng-pristine ng-untouched ng-valid ng-empty" id="KARB"
                                                           data-ng-model="challanData.bank_cd" name="bank_cd" value="KARB"
                                                           data-ng-change="chkDis()" type="radio">
                                                    <label for="KARB">KARNATAKA BANK LIMITED</label>
                                                </li>&lt;!&ndash;&ndash;&gt;
                                                <li data-ng-repeat="pb in preferredBanks">
                                                    <input class="form-control ng-pristine ng-untouched ng-valid ng-empty" id="BARB"
                                                           data-ng-model="challanData.bank_cd" name="bank_cd" value="BARB"
                                                           data-ng-change="chkDis()" type="radio">
                                                    <label for="BARB">BANK OF BARODA</label>
                                                </li>&lt;!&ndash;&ndash;&gt;
                                                <li data-ng-repeat="pb in preferredBanks">
                                                    <input class="form-control ng-pristine ng-untouched ng-valid ng-empty" id="SIBL"
                                                           data-ng-model="challanData.bank_cd" name="bank_cd" value="SIBL"
                                                           data-ng-change="chkDis()" type="radio">
                                                    <label for="SIBL">SOUTH INDIAN BANK</label>
                                                </li>-->
                    </ul>
                </div>

            </div>
            <!---->
            <!---->
            <!---->
            <!---->
            <!---->

        </div>

    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'ModeOfEpayment',
    data () {
        return {
            paymentBanks: '',
            fetchUrl: urls.bankDetails,
            paymentDetails:
                    {
                        payment_mode: '',
                        bank: ''
                    }
        };
    },
    props: {
        disable: {}
    },
    mounted () {
        /* this.fetchInfo('preferred banks'); */
        // this.selectPreferredBanks('NB');
    },
    methods: {
        selectPreferredBanks (mode) {
            /* this.paymentDetails.payment_mode = 'preferred banks'; */
            /*   console.log('Mode', mode); */
            switch (mode) {
            case 'PB':
                console.log('Mode', mode);
                this.disable = true;
                this.$emit('disableStateChange', this.disable);
                this.paymentDetails.payment_mode = 'preferred banks';
                this.fetchInfo(this.paymentDetails.payment_mode);
                break;
            case 'NB':
                console.log('Mode', mode);
                this.disable = true;
                this.$emit('disableStateChange', this.disable);
                this.paymentDetails.payment_mode = 'net banking';
                this.fetchInfo(this.paymentDetails.payment_mode);
                break;
            case 'BU':
                console.log('Mode', mode);
                this.disable = true;
                this.$emit('disableStateChange', this.disable);
                this.paymentDetails.payment_mode = 'bhim upi';
                this.fetchInfo(this.paymentDetails.payment_mode);
                break;
            case 'CDB':
                console.log('Mode', mode);
                this.disable = true;
                this.paymentDetails.payment_mode = 'credit or debit card';
                this.fetchInfo(this.paymentDetails.payment_mode);
                break;
            default:
                console.log('Invalid Mode');
            }
            this.emitSuccess();
        },

        emitSuccess (item) {
            if (item) {
                console.log('bank Id', item);
                this.paymentDetails.bank = item;
            }
            console.log('sucesspayment', this.paymentDetails);
            this.$emit('success', this.paymentDetails);
        },

        async fetchInfo (paymentMode) {
            console.log('fetchFunctionCalled');
            try {
                const response = await axios.form(this.fetchUrl, { payment_mode: paymentMode });

                const result = response.data;
                this.paymentBanks = result.data;
                console.log('result', result);
                if (this.items) {

                    /* await this.$router.push({ path: '/project-list/' }); */
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            console.log(errorMessage);
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
};
</script>

<style scoped>
    .payment-mode-active i {
        display: inline;
    }

    .mr-1 {
        margin-right: 0.5rem !important;
    }
</style>
