const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api/';
} else {
    baseUrl = '/api/';
}

export default {
    login: baseUrl + 'challan/login/',
    chellanCreation: {
        companyDetail: baseUrl + 'master-data/company-detail/',
        addEdit: baseUrl + 'challan/add-edit/',
        amountConversion: baseUrl + 'challan/amount-to-words/'
    },
    generatedChellan: baseUrl + 'challan/detail/',
    bankDetails: baseUrl + 'master-data/bank-list/',
    challanDownloadPdf: baseUrl + 'challan/pdf-download/',
    makePayment: baseUrl + 'challan/make-payment/'
};
