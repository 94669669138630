<template>
    <div>
        <div class="container py-3 ">
            <div class="row" data-ng-controller="transctrl" data-ng-init="init('payment')">
                <div class="col-xs-10">
                    <div data-breadcrumb="" data-path="//www.gst.gov.in/" data-name="Home">
                        <ol class="breadcrumb" data-ng-controller="crumbCtrl">
                            <li><a target="" href="/home/" data-ng-bind="name">Home</a></li><!---->
                            <li data-ng-repeat="breadcrumb in breadcrumbs.getAll()">
                                <ng-switch on="$last"> <!----> <!----><span ng-switch-default=""><a
                                        href="/payment/quick-links/payments" data-ng-bind="breadcrumb.n"
                                        target="_self">Payment</a></span>
                                    <!----> </ng-switch>
                            </li><!---->
                            <li data-ng-repeat="breadcrumb in breadcrumbs.getAll()">
                                <ng-switch on="$last"> <!----><span ng-switch-when="true">Create Challan</span><!---->
                                    <!----> </ng-switch>
                            </li><!----></ol>
                    </div>
                </div>
                <div class="col-xs-2">
                    <div class="lang dropdown">
                        <span class="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                        <ul class="dropdown-menu lang-dpdwn">
                            <!---->
                            <li data-ng-repeat="language in languages">
                                <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
                            </li><!---->
                        </ul>
                    </div>
                </div>
            </div>
            <json-viewer :value="jsonData" class="min-h-100vh p-3"
                         :expand-depth=5
                         copyable
                         sort>
            </json-viewer>
        </div>
    </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer';
import store from '../store';

export default {
    name: 'CreateChallanJsonResponsePage',
    components: { JsonViewer },
    data () {
        return {
            jsonData: ''
        };
    },
    mounted () {
        this.getPaymentData();
    },
    methods: {
        getPaymentData () {
            const paymentData = store.getters;
            console.log('payment Data', paymentData);
            this.jsonData = paymentData.jsonFormat;
            console.log('payment', this.jsonData);
        }
    }
};
</script>

<style scoped>
    .min-h-100vh {
        min-height: 100vh;
    }

    .p-3 {
        padding: 2rem !important;
    }
</style>
