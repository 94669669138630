<script>
export default {
    name: 'CreateChallan'
};
</script>

<template>
  <div>
    <div class="row" data-ng-controller="transctrl" data-ng-init="init('payment')">
      <div class="col-xs-10">
        <div data-breadcrumb="" data-path="//www.gst.gov.in/" data-name="Home">
          <ol class="breadcrumb" data-ng-controller="crumbCtrl">
            <li><a target="" href="/home/" data-ng-bind="name">Home</a></li><!---->
            <li data-ng-repeat="breadcrumb in breadcrumbs.getAll()">
              <span ng-switch-default="">
                <a
                    href="/payment/quick-links/payments" data-ng-bind="breadcrumb.n" target="_self">Payment</a></span>
              <!---->
            </li><!---->
            <li data-ng-repeat="breadcrumb in breadcrumbs.getAll()">
              <ng-switch on="$last"> <!----><span ng-switch-when="true">Create Challan</span><!----> <!---->
              </ng-switch>
            </li><!----></ol>
        </div>
      </div>
      <div class="col-xs-2">
        <div class="lang dropdown">
          <span class="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
          <ul class="dropdown-menu lang-dpdwn">
            <!---->
            <li data-ng-repeat="language in languages">
              <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
            </li><!---->
          </ul>
        </div>
      </div>
    </div>

    <div ng-view="">
      <div class="tabpane">
        <div class="row">
          <div class="col-lg-offset-3 col-md-offset-3 col-sm-offset-3 col-lg-6 col-md-6 col-sm-6">
            <div class="padded-box">

              <!---->
              <h4 data-ng-bind="trans.HEAD_CRT_CHALLAN">Create Challan</h4>
              <div class="page-format">
                <p class="mand-text" data-ng-bind="trans.HLP_MAND_FIELD">indicates mandatory fields</p>
                <form class="ng-pristine ng-invalid ng-invalid-required ng-valid-minlength ng-valid-maxlength"
                      role="form" name="prelogin" data-ng-submit="next()" autocomplete="off" novalidate="">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                         data-ng-class="{ 'has-error':(prelogin.$submitted &amp;&amp; prelogin.gstin.$invalid) || errMsg}">
                      <label class="reg m-cir" for="gstin" data-ng-bind="trans.LBL_PRE_REG">GSTIN/Other Id</label>
                      <input type="text" placeholder="Enter GSTIN/Other Id"
                             class="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-minlength ng-valid-maxlength"
                             data-ng-init="prelogindtls.gstin=''" name="gstin" data-ng-model="prelogindtls.gstin"
                             maxlength="15" data-ng-minlength="15"
                             data-ng-change="prelogin.gstin.$setValidity('pattern', true);" required="">
                      <span
                          ng-show="(prelogin.$submitted) &amp;&amp; ((prelogin.gstin.$error.pattern) || (prelogin.gstin.$error.minlength))"
                          data-ng-bind="trans.ERR_INV_GSTIN" class="err ng-hide">Invalid GSTIN/Other Id</span>
                      <span ng-show="prelogin.$submitted &amp;&amp; prelogin.gstin.$error.required"
                            data-ng-bind="trans.ERR_MANDATORY" class="err ng-hide">This field is mandatory</span>
                      <span data-ng-bind="errMsg" class="err"></span>
                    </div>
                  </div>

                  <!---->
                  <!---->

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <button type="submit" class="btn btn-primary" title="Proceed" data-ng-bind="trans.LBL_PROC">
                        Proceed
                      </button>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
