<template>
    <div>
        <div class="row">
            <div class="col-xs-10">
                <div data-breadcrumb="" data-path="//www.gst.gov.in/" data-name="Home">
                    <ol class="breadcrumb">
                        <li><a target="" href="/home/">Home</a></li><!---->
                        <li>
                            <span>
                <a
                        href="/payment/quick-links/payments"
                        target="_self">Payment</a></span>
                        </li><!---->
                        <li>
                            <!----><span>Create Challan</span><!---->
                            <!---->
                        </li><!----></ol>
                </div>
            </div>
            <div class="col-xs-2">
                <div class="lang dropdown">
                    <span class="dropdown-toggle" data-toggle="dropdown">English</span>
                    <ul class="dropdown-menu lang-dpdwn">
                        <!---->
                        <li>
                            <a>English</a>
                        </li><!---->
                    </ul>
                </div>
            </div>
        </div>
        <!---->
        <div>
            <div class="tabpane">
                <div class="row">
                    <div class="col-lg-offset-3 col-md-offset-3 col-sm-offset-3 col-lg-6 col-md-6 col-sm-6">
                        <div class="padded-box">

                            <!---->
                            <h4>Create Challan</h4>
                            <div class="page-format">
                                <p class="mand-text">indicates mandatory fields</p>
                                <form class="ng-invalid ng-invalid-required ng-valid-maxlength ng-dirty ng-valid-parse ng-valid-pattern ng-valid-minlength"
                                      role="form" name="prelogin" autocomplete="off"
                                      novalidate="" @submit.prevent="onSubmitLogin">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <label class="reg m-cir">GSTIN/Other
                                                Id</label>
                                            <input type="text" placeholder="Enter GSTIN/Other Id" v-model="model.gst_no"
                                                   class="form-control ng-valid-maxlength ng-touched ng-not-empty ng-dirty ng-valid-parse ng-valid-required ng-valid-pattern ng-valid ng-valid-minlength"
                                                   data-ng-init="prelogindtls.gstin=''" name="gstin"
                                                   data-ng-model="prelogindtls.gstin" maxlength="15"
                                                   data-ng-minlength="15"
                                                   data-ng-change="prelogin.gstin.$setValidity('pattern', true);"
                                                   required="">
                                            <!--                                            <span ng-show="(prelogin.$submitted) &amp;&amp; ((prelogin.gstin.$error.pattern) || (prelogin.gstin.$error.minlength))"-->
                                            <!--                                                  data-ng-bind="trans.ERR_INV_GSTIN" class="err ng-hide">Invalid GSTIN/Other Id</span>-->
                                            <!--                                            <span ng-show="prelogin.$submitted &amp;&amp; prelogin.gstin.$error.required"-->
                                            <!--                                                  data-ng-bind="trans.ERR_MANDATORY" class="err ng-hide">This field is mandatory</span>-->
                                            <!--                                            <span data-ng-bind="errMsg" class="err"></span>-->
                                        </div>
                                    </div>

                                    <!---->
                                    <div class="row" v-if="model.gst_no !==''">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <label class="reg m-cir">Type
                                                the characters as displayed below</label>
                                            <input type="text" maxlength="6" v-model="model.captcha"
                                                   class="form-control number ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-minlength ng-valid-maxlength"
                                                   id="message" placeholder="Type the characters as displayed below"
                                                   name="captcha"
                                                   required="">
                                            <!--                                            <span data-ng-show="prelogin.$submitted &amp;&amp; prelogin.captcha.$error.required"-->
                                            <!--                                                  data-ng-bind="trans.ERR_MANDATORY" class="err ng-hide">This field is mandatory</span>-->
                                            <!--                                            <span ng-show="(prelogin.$submitted) &amp;&amp; ((prelogin.captcha.$error.minlength)) || CapErr"-->
                                            <!--                                                  data-ng-bind="trans.ERR_CAPTCHA" class="err ng-hide">Enter valid Letters shown</span>-->
                                            <!--<span data-ng-bind="CapErr" class="err"></span>-->
                                            <p class="err ng-hide" v-if="!captchaCheckStatus">Enter valid Letters
                                                shown</p>
                                        </div>
                                    </div><!---->
                                    <!---->
                                    <div class="row" v-if="model.gst_no !==''">
                                        <div class="col-xs-12">
                                            <div>

                                                <div data-captcha="" data-captcha-object="captchaObj"><!---->
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <th rowspan="2"><img id="imgCaptcha"
                                                                                 class="captcha"
                                                                                 :src="currentCapcha.img">
                                                            </th>
                                                            <th>
                                                                <button type="button"><i
                                                                        class="fa fa-volume-up"></i></button>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <button type="button" @click="setCapcha"><i
                                                                        class="fa fa-refresh"></i></button>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    <p class="err ng-hide"><br/>{{errorMessage}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div><!---->

                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <button type="submit" class="btn btn-primary" title="Proceed">Proceed
                                            </button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--        <button @click="getPaymentData">get payment data</button>-->
    </div>
</template>
<script>
import captcha from '../assets/img/captcha.png';
import captcha1 from '../assets/img/captcha1.png';
import captcha2 from '../assets/img/captcha2.png';
import captcha3 from '../assets/img/captcha3.png';
import captcha4 from '../assets/img/captcha4.png';
import urls from '../data/urls';
import axios from 'secure-axios';
import store from '@/store';

export default {
    name: 'CreateChallanOne',
    data () {
        return {
            loginUrl: urls.login,
            capchas: [
                {
                    img: captcha,
                    value: '272876'
                },
                {
                    img: captcha1,
                    value: '852279'
                },
                {
                    img: captcha2,
                    value: '132372'
                },
                {
                    img: captcha3,
                    value: '973077'
                },
                {
                    img: captcha4,
                    value: '707424'
                }
            ],
            currentCapcha: {
                img: captcha,
                value: '777876'
            },
            model: {
                gst_no: '',
                captcha: ''
            },
            captchaCheckStatus: true,
            errorMessage: ''
        };
    },
    mounted () {
        this.setCapcha();
    },
    methods: {
        setCapcha () {
            this.currentCapcha = this.capchas[(Math.floor(Math.random() * this.capchas.length))];
        },
        checkCaptcha () {
            console.log('current captche, model captche', this.currentCapcha.value, typeof this.currentCapcha.value, this.model.captcha, typeof this.model.captcha);
            return this.model.captcha === this.currentCapcha.value;
        },
        async onSubmitLogin () {
            this.captchaCheckStatus = this.checkCaptcha();
            if (!this.captchaCheckStatus) {
                return '';
            }
            const response = await axios.form(this.loginUrl, this.model);
            if (response.data.success) {
                console.log('response', response);
                await store.commit('setPayment', response.data.data);
                this.proceedToChallanReason();
            } else {
                const errors = response.data.errors;

                for (const key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        // const errorMessage = `${key}:  ${errors[key]}`;
                        const errorMessage = `${errors[key]}`;
                        console.log(errorMessage);
                        this.errorMessage = errorMessage;
                    }
                }
            }
        },
        getPaymentData () {
            const paymentData = store.getters.paymentData;
            console.log('payment-data', paymentData);
        },
        proceedToChallanReason () {
            this.$router.push('/challan-reason/');
        }
    }
};
</script>
<style scoped>

</style>
