import { render, staticRenderFns } from "./DetailsOfTaxPayer.vue?vue&type=template&id=281b75fa&scoped=true&"
import script from "./DetailsOfTaxPayer.vue?vue&type=script&lang=js&"
export * from "./DetailsOfTaxPayer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "281b75fa",
  null
  
)

export default component.exports