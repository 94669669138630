import Vue from 'vue';
import Vuex from 'vuex';
import payment from './payment';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        payment
    }
});
