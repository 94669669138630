<template>
    <div>
        <Header/>
        <div class="container">
            <div class="mypage">
                <router-view></router-view>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>

import Header from '@/components/Header.vue';

import './assets/css/fa-bootstrap1.2.css';
import './assets/css/app1.1.css';
import './assets/css/datepicker1.0.css';
import './assets/css/fullcalendar.min.css';
import './assets/css/ng-table.min.css';
import 'font-awesome/css/font-awesome.min.css';

import Footer from '@/components/Footer.vue';

export default {
    name: 'App',
    components: { Footer, Header },
    data () {
        return {};
    }
};
</script>

<style scoped>
    .mypage{
        min-height: 80vh;
    }
</style>
