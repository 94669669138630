<template>
    <div data-ng-if="challanData.chln_rsn &amp;&amp; IS_CHALLAN_REASON_ENABLED">
        <div class="row">
            <div class="col-sm-4 col-xs-12"><h4 data-ng-bind="trans.HEAD_RSN_FR_CHLN">Reason For Challan</h4></div>
            <!---->
            <div class="col-sm-8 col-xs-12" data-ng-if="isApplicableForReason"
                 style="padding-top: 10px; text-align: right;"> <!----><!----><a data-ng-if="isDisplayBackLink"
                                                                                 href="javascript:void(0)"
                                                                                 id="back-link" title="Edit Reason"
                                                                                 data-ng-click="onBack()"
                                                                                 data-ng-bind="trans.LBL_EDIT_RSN">Edit
                Reason</a><!----> </div><!----> </div>
        <div class="tbl-format">
            <div class="row">
                <div class="inner">
                    <div class="col-sm-4 col-xs-12"><p data-ng-bind="trans.LBL_REASON">Reason</p><!----><!---->
                        <!----><span data-ng-if="challanData.chln_rsn === 'AOP'"><strong data-ng-bind="trans.LBL_AOP">Any other payment</strong></span>
                        <!----> </div><!----><!----> </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReasonForChallan',
    props: {
        details: {
            type: Object
        }
    }
};
</script>

<style scoped>

</style>
