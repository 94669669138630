<template>
    <div>
        <breadcrumb></breadcrumb>
        <div class="tabpane">
            <details-of-tax-payer :details="details" :taxpayer="companyDetails"></details-of-tax-payer>
            <reason-for-challan :details="details"></reason-for-challan>
            <details-of-deposit-table-inputs :details="details"></details-of-deposit-table-inputs>
            <mode-of-epayment @success="modelConversion" @disableStateChange="changeState" :disable="disableState"></mode-of-epayment>
            <gst-verification-modal></gst-verification-modal>
            <div class="row">
                <div class="col-sm-12 col-xs-12 text-right">

                    <button type="button" class="btn btn-default send " title="Download"
                             @click="downloadReport(details.id)">Download
                    </button>
                    <!---->
                    <button type="submit" data-ng-if="confHide" class="btn btn-primary send " title="Make Payment"
                            data-ng-bind="trans.LBL_MP" :disabled=disableState @click="submitData">Make Payment
                    </button><!---->
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 subpayalign">
                    <!----><span data-ng-if="chargesHelpText"><strong><span class="fa fa-info-circle"></span>
                        <span data-ng-bind="trans.HLP_BANK_STATUS_PAYMENT_A">The taxpayers are advised to check the transaction charges before selecting the Credit Card, Debit Card and BHIM UPI sub-payment mode. The transaction charges if any, will be applicable over and above the tax amount in this payment option. Kindly refer to the respective Bank's site for more details.</span></strong>
                </span><!---->
                </div>
                <div class="col-xs-12 subpayalign">
                    <!---->
                </div>
                <div class="col-xs-12 subpayalign">
                <span><strong><span class="fa fa-info-circle "></span><span data-ng-bind="trans.HLP_GRV">If amount is deducted from bank account and not reflected in electronic cash ledger, you may raise grievance under Services&gt;Payments&gt;Grievance against payment(GST PMT-07) </span>
                    </strong>
                </span>
                </div>
                <div class="col-xs-12 subpayalign">
                <span><strong><span class="fa fa-info-circle "></span> <span data-ng-bind="trans.HLP_BANK_STATUS_EPY">*Awaiting Bank Confirmation: For e-payment mode of payment, if the maker has made a transaction and checker approval is not communicated by bank to GST System.</span></strong>
                </span><br>
                </div>
                <div class="col-xs-12 subpayalign">
                <span><strong><span class="fa fa-info-circle "></span> <span data-ng-bind="trans.HLP_BANK_STATUS_OTC">*Awaiting Bank Clearance: For OTC mode of payment, if bank has acknowledged the challan but remittance confirmation is not communicated by bank to GST System.</span></strong>
                </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DetailsOfDepositTableInputs from './components/DetailsOfDepositTableInputs';
import ReasonForChallan from './components/ReasonForChallan';
import Breadcrumb from './components/Breadcrumb';
import DetailsOfTaxPayer from './components/DetailsOfTaxPayer';
import ModeOfEpayment from './components/ModOfEpayment';
import GstVerificationModal from './components/GstVerificationModal';
import axios from 'secure-axios';
import urls from '../../data/urls';
import store from '../../store';

export default {
    name: 'CreateChallanPage',
    components: {
        GstVerificationModal,
        ModeOfEpayment,
        DetailsOfTaxPayer,
        Breadcrumb,
        ReasonForChallan,
        DetailsOfDepositTableInputs
    },
    data () {
        return {

            disableState: true,
            challanId: this.$route.params.id,
            gst_no: '',
            setJsonFormatedData: '',
            fetchUrl: urls.generatedChellan,
            downloadUrl: urls.challanDownloadPdf,
            saveUrl: urls.makePayment,
            taxpayerFetchUrl: urls.chellanCreation.companyDetail,
            model: {
                id: this.$route.params.id,
                payment_mode: '',
                bank: ''
            },

            companyDetails: '',
            details: ''
        };
    },
    mounted () {
        this.getPaymentData();
        this.fetchInfo();
        this.fetchInfoCompanyDetails();
    },

    methods: {
        changeState (item) {
            this.disableState = item;

            console.log('disable State changed', this.disableState);
        },
        checkDisableState () {
            console.log('Bank', this.model);
            if (this.model.bank) {
                this.disableState = false;
            } else {
                this.disableState = true;
            }
        },
        modelConversion (item) {
            this.model.payment_mode = item.payment_mode;
            this.model.bank = item.bank;
            console.log('model', item);
            this.checkDisableState();
        },
        async downloadReport (id) {
            try {
                console.log('id', id);
                const that = this;

                const response = await axios.form(this.downloadUrl, { id: id });
                const json = response.data;
                if (json.error === false) {
                    that.downloading = false;
                    window.open(json.url);
                    console.log('Downloaded Succesfully');
                } else {
                    that.downloading = false;
                    that.error = true;
                    that.message = json.message;
                }
            } catch (error) {
                console.log(error);
            }
        },

        getPaymentData () {
            const paymentData = store.getters;
            console.log('payment Data', paymentData);
            this.gst_no = paymentData.paymentData.gst_no;
            console.log('payment Data', this.gst_no);
        },
        async fetchInfo () {
            console.log('challanId', this.$route.params.id);
            console.log('fetchFunctionCalled', this.gst_no);
            try {
                const response = await axios.form(this.fetchUrl, { id: this.challanId });
                const result = response.data;
                this.details = result.data;
                console.log('result', result);
                if (this.items) {

                    /* await this.$router.push({ path: '/project-list/' }); */
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            console.log(errorMessage);
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        async submitData () {
            try {
                const response = await axios.form(this.saveUrl, this.model);

                const result = response.data;
                console.log('result', result);
                if (result.success) {
                    const items = result.data;
                    const requestUrl = result.request_url;

                    this.redirectToPaymentPage(items, requestUrl);
                    /* this.bankCall(items, requestUrl); */

                    /* await this.$router.push({ path: '/project-list/' }); */
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            console.log(errorMessage);
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        redirectToPaymentPage (data, actionUrl) {
            // Create a form element
            const form = document.createElement('form');
            form.method = 'POST';
            form.action = actionUrl;

            // Create a hidden input field for the data
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = 'enc_data';
            input.value = JSON.stringify(data);

            // Append the input field to the form
            form.appendChild(input);

            // Append the form to the body
            document.body.appendChild(form);

            // Submit the form
            form.submit();

            // Remove the form from the body
            document.body.removeChild(form);
        },
        async bankCall (data, dataUrl) {
            console.log('Data in bank call', data);
            console.log('url in bank call', dataUrl);
            try {
                /* window.open(dataUrl); */
                await store.commit('setJsonFormat', data);

                /* this.$router.push('/create-challan-json-view/'); */
                /* const response = await axios.form(dataUrl, data);

                const result = response.data;
                console.log('result', result);
                if (result.success) {

                    /!* await this.$router.push({ path: '/project-list/' }); *!/
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            console.log(errorMessage);
                        }
                    }
                } */
            } catch (error) {
                console.log(error);
            }
        },
        async fetchInfoCompanyDetails () {
            console.log('fetchFunctionCalled');
            try {
                const response = await axios.form(this.taxpayerFetchUrl, { gst_no: this.gst_no });

                const result = response.data;
                this.companyDetails = result.data;
                console.log('result', result);
                if (result.success) {
                    /* const data = result.data;
                    console.log('Data', data);
                    this.bankCall(); */
                    /* await this.$router.push({ path: '/project-list/' }); */
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            console.log(errorMessage);
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
};
</script>

<style scoped>

</style>
