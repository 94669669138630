<template>
    <div>
        <h4 data-ng-bind="trans.HEAD_BP_TAX ">Details of Deposit</h4>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="table-responsive">
                    <table class="table table-striped table-bordered" style="width:99%">
                        <thead>
                            <tr>
                                <th>
                                    <p></p>
                                </th>
                                <th>
                                    <div class="row">
                                        <div class="col-xs-12 text-center">
                                            <span data-ng-bind="trans.HEAD_TAX">Tax</span> <span> (₹)</span>
                                        </div>
                                    </div>

                                </th>
                                <th>
                                    <div class="row">
                                        <div class="col-xs-12 text-center">
                                            <span data-ng-bind="trans.HEAD_INTR">Interest</span> <span> (₹)</span>
                                        </div>
                                    </div>

                                </th>
                                <th>
                                    <div class="row">
                                        <div class="col-xs-12 text-center">
                                            <span data-ng-bind="trans.HEAD_PENALTY">Penalty</span> <span> (₹)</span>
                                        </div>
                                    </div>

                                </th>
                                <th>
                                    <div class="row">
                                        <div class="col-xs-12 text-center">
                                            <span data-ng-bind="trans.HEAD_FEES">Fees</span> <span> (₹)</span>
                                        </div>
                                    </div>

                                </th>
                                <th>
                                    <div class="row">
                                        <div class="col-xs-12 text-center">
                                            <span data-ng-bind="trans.HEAD_OTHER">Other</span> <span> (₹)</span>
                                        </div>
                                    </div>

                                </th>
                                <th>
                                    <div class="row">
                                        <div class="col-xs-12 text-center">
                                            <span data-ng-bind="trans.HEAD_TOT">Total</span> <span> (₹)</span>
                                        </div>
                                    </div>

                                </th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <label data-ng-bind="trans.HEAD_CGST" title="Central Goods &amp; Services Tax">CGST(0005)</label>
                                </td>

                                <td class="text-right">
                                    <label data-ng-bind="challanData.cgst_tax_amt | INR:'pay'">{{details.cgst_tax}}</label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.cgst_int_amt | INR:'pay'">{{details.cgst_interest}}</label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.cgst_pen_amt | INR:'pay'">{{details.cgst_penalty}}</label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.cgst_fee_amt | INR:'pay'">{{details.cgst_fees}}</label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.cgst_oth_amt | INR:'pay'">{{details.cgst_other}}</label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.cgst_tot_amt | INR:'pay'">{{details.cgst_total}}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label data-ng-bind="trans.HEAD_IGST" title="Integrated Goods &amp; Services Tax">IGST(0008)</label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.igst_tax_amt | INR:'pay'">{{details.igst_tax}}</label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.igst_int_amt | INR:'pay'">{{details.igst_interest}}</label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.igst_pen_amt | INR:'pay'">{{details.igst_penalty}}</label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.igst_fee_amt | INR:'pay'">{{details.igst_fees}}</label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.igst_oth_amt | INR:'pay'">{{details.igst_other}}</label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.igst_tot_amt | INR:'pay'">{{details.igst_total}}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label data-ng-bind="trans.HEAD_CESS">CESS(0009)</label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.cess_tax_amt | INR:'pay'">{{details.cess_tax}}</label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.cess_int_amt | INR:'pay'">{{details.cess_interest}}</label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.cess_pen_amt | INR:'pay'">{{details.cess_penalty}}</label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.cess_fee_amt | INR:'pay'">{{details.cess_fees}}</label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.cess_oth_amt | INR:'pay'">{{details.cess_other}}</label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.cess_tot_amt | INR:'pay'">{{details.cess_total}}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label title="State Goods &amp; Services Tax">Kerala <span data-ng-show="sgSt" data-ng-bind="trans.LBL_SG_ST">SGST(0006)</span><span data-ng-show="utSt" data-ng-bind="trans.LBL_UT_ST" class="ng-hide">UTGST(0007)</span></label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.sgst_tax_amt | INR:'pay'">{{details.kerala_sgst_tax}}</label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.sgst_int_amt | INR:'pay'">{{details.kerala_sgst_interest}}</label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.sgst_pen_amt | INR:'pay'">{{details.kerala_sgst_penalty}}</label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.sgst_fee_amt | INR:'pay'">{{details.kerala_sgst_fees}}</label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.sgst_oth_amt | INR:'pay'">{{details.kerala_sgst_other}}</label>
                                </td>
                                <td class="text-right">
                                    <label data-ng-bind="challanData.sgst_tot_amt | INR:'pay'">{{details.kerala_sgst_total}}</label>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <label data-ng-bind="trans.HEAD_TOT_CHLNAMT">Total Challan Amount:</label>
                                </td>
                                <td colspan="5">
                                    <span>  ₹</span>
                                    <label data-ng-bind="challanData.total_amt |INR:'pay'">{{details.total_challan_amount}}</label>
                                    <!----><label data-ng-if="challanData.total_amt"> /-</label><!---->
                                </td>

                            </tr>
                            <tr>
                                <td colspan="2">
                                    <label data-ng-bind="trans.HEAD_TOTAMT_WORDS">Total Challan Amount (In Words):</label>
                                </td>
                                <td colspan="5">
                                    <label data-ng-bind="challanData.total_amt_word">{{details.amount_in_words}}</label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!---->
    </div>
</template>

<script>
export default {
    name: 'DetailsOfDepositTableInputs',
    props: {
        details: {
            type: Object
        }
    }
};
</script>

<style scoped>

</style>
