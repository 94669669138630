<template>
  <div>
    <div class="row">
      <div class="col-xs-10">
        <div data-breadcrumb="" data-path="//www.gst.gov.in/" data-name="Home">
          <ol class="breadcrumb">
            <li><a target="" href="//www.gst.gov.in/">Home</a></li><!---->
            <li><!----> <!----><span><a target="_self">Payment</a></span><!----> </li><!---->
            <li><!----><span>Create Challan</span><!----> <!----> </li><!----></ol>
        </div>
      </div>
      <div class="col-xs-2">
        <div class="lang dropdown">
          <span class="dropdown-toggle" data-toggle="dropdown">English</span>
          <ul class="dropdown-menu lang-dpdwn">
            <!---->
            <li>
              <a>English</a>
            </li><!---->
          </ul>
        </div>
      </div>
    </div>
    <div class="tabpane">
      <!---->
      <!---->

      <!--  <div class="alert alert-danger" >
          <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
          <strong ></strong>
      </div>-->
      <!---->

      <h4></h4>
      <div class="row">
        <div class="col-sm-4 col-xs-12">
          <h4>Details of Taxpayer</h4>
        </div>
      </div>
      <div class="tbl-format">
        <div class="row">
          <div class="inner">
            <div class="col-sm-4 col-xs-12 ">
              <p>GSTIN/Other Id</p>
              <span><strong>{{ details.gst_no }}</strong></span>
            </div>
            <div class="col-sm-4 col-xs-12 ">
              <p>Email Address</p>
              <span>
                        <strong style="word-wrap:break-word;">{{ details.email }}</strong>
                    </span>
            </div>
            <div class="col-sm-4 col-xs-12 ">
              <p>Mobile Number</p>
              <span><strong>{{ details.mobile_number }}</strong></span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="inner">
            <div class="col-sm-4 col-xs-12 ">
              <p>Name</p>
              <span><strong>{{ details.name }}</strong></span>
            </div>
            <div class="col-sm-4 col-xs-12 ">
              <p>Address</p>
              <span><strong>{{ details.address }}</strong></span>
            </div>
          </div>
        </div>
      </div>
      <challan-reason-section><!---->
        <div>
          <div class="row">
            <div class="col-sm-4 col-xs-12"><h4>Reason For Challan</h4></div><!---->
            <div class="col-sm-8 col-xs-12" style="padding-top: 10px; text-align: right;"> <!----><!----><a
                href="javascript:void(0)" id="back-link" title="Edit Reason">Edit Reason</a><!----> </div><!----> </div>
          <div class="tbl-format">
            <div class="row">
              <div class="inner">
                <div class="col-sm-4 col-xs-12"><p>Reason</p><!----><!---->
                  <!----><span><strong>Any other payment</strong></span><!----> </div><!----><!----> </div>
            </div>
          </div>
        </div><!----></challan-reason-section>
      <form class="ng-pristine ng-valid ng-valid-pattern ng-valid-maxlength" role="form" name="challanTab"
            autocomplete="off" novalidate="" @submit.prevent="addItem">
        <div class="row">
          <div class="col-sm-4 col-xs-12">
            <h4>Details of Deposit</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="table-responsive">
              <table style="width:99%" class="table tbl inv table-bordered exp">
                <thead>
                <tr>
                  <th style="width:14%">
                    <p></p>
                  </th>
                  <th style="width:14%">
                    <div class="row">
                      <div class="col-xs-12 text-center">
                        <!--  <p ></p>(&#8377;)-->
                        <label>Tax</label>
                        <label>
                          (
                          <i class="fa fa-inr" araia-hidden="true"></i>)
                        </label>
                      </div>
                    </div>
                  </th>
                  <th style="width:14%">
                    <div class="row">
                      <div class="col-xs-12 text-center">
                        <label>Interest</label>
                        <label>
                          (
                          <i class="fa fa-inr" araia-hidden="true"></i>)
                        </label>
                      </div>
                    </div>

                  </th>
                  <th style="width:14%">
                    <div class="row">
                      <div class="col-xs-12 text-center">
                        <label>Penalty</label>
                        <label>
                          (
                          <i class="fa fa-inr" araia-hidden="true"></i>)
                        </label>
                      </div>
                    </div>

                  </th>
                  <th style="width:14%">
                    <div class="row">
                      <div class="col-xs-12 text-center">
                        <label>Fees</label>
                        <label>
                          (
                          <i class="fa fa-inr" araia-hidden="true"></i>)
                        </label>
                      </div>
                    </div>
                  </th>
                  <th style="width:14%">
                    <div class="row">
                      <div class="col-xs-12 text-center">
                        <label>Other</label>
                        <label>
                          (
                          <i class="fa fa-inr" araia-hidden="true"></i>)
                        </label>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div class="row">
                      <div class="col-xs-12 text-center">
                        <label>Total</label>
                        <label>
                          (
                          <i class="fa fa-inr" araia-hidden="true"></i>)
                        </label>
                      </div>
                    </div>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    <label title="Central Goods &amp; Services Tax">CGST(0005)</label>
                  </td>
                  <td>
                    <input type="number" v-model="model.cgst_tax" @input="cgstTotal"
                           class="form-control textbox txt number text-right ng-pristine ng-valid ng-empty ng-valid-pattern ng-valid-maxlength ng-touched"
                           autofocus="" maxlength="10" totalfire="">
                  </td>
                  <td>
                    <input v-model="model.cgst_interest" @input="cgstTotal"
                           class="form-control textbox txt number text-right ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength"
                           type="number" maxlength="10" totalfire="">
                  </td>
                  <td>
                    <input v-model="model.cgst_penalty" @input="cgstTotal"
                           class="form-control textbox txt number text-right ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength"
                           type="number" maxlength="10" totalfire="">
                  </td>
                  <td>

                    <input v-model="model.cgst_fees" @input="cgstTotal"
                           class="form-control textbox txt number text-right ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength"
                           name="cgst_fee_amt" id="cgst_fee_amt" type="number" maxlength="10" totalfire="">
                  </td>

                  <td>
                    <input v-model="model.cgst_other" @input="cgstTotal"
                           class="form-control textbox txt number text-right ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength"
                           name="cgst_oth_amt" type="number" maxlength="10" totalfire="" fdprocessedid="ydv96">
                  </td>
                  <td width="15%">

                    <input v-model="model.cgst_total" class="form-control textbox text-right" type="number" disabled=""
                           totalfire="">
                  </td>
                </tr>
                <tr>
                  <td>
                    <label title="Integrated Goods &amp; Services Tax">IGST(0008)</label>
                  </td>
                  <td>
                    <input v-model="model.igst_tax" @input="igstTotal"
                           class="form-control textbox txt1 number text-right ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength"
                           name="igst_tax_amt" type="number" maxlength="10" totalfire="" fdprocessedid="38g0gl">
                  </td>
                  <td>
                    <input v-model="model.igst_interest" @input="igstTotal"
                           class="form-control textbox txt1 number text-right ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength"
                           type="number" name="igst_int_amt" maxlength="10" totalfire="" fdprocessedid="upltfh">
                  </td>
                  <td>
                    <input v-model="model.igst_penalty" @input="igstTotal"
                           class="form-control textbox txt1 number text-right ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength"
                           name="igst_pen_amt" type="number" maxlength="10" totalfire="" fdprocessedid="33ce1">
                  </td>
                  <td>
                    <input v-model="model.igst_fees" @input="igstTotal"
                           class="form-control textbox txt1 number text-right ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength"
                           name="igst_fee_amt" id="igst_fee_amt" type="number" maxlength="10" totalfire=""
                           fdprocessedid="x8mh9r">
                  </td>

                  <td>
                    <input v-model="model.igst_other" @input="igstTotal"
                           class="form-control textbox txt1 number text-right ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength"
                           type="number" name="igst_oth_amt" maxlength="10" totalfire="" fdprocessedid="37hkix">
                  </td>
                  <td>
                    <input v-model="model.igst_total" class="form-control textbox text-right" type="number" disabled=""
                           totalfire="" fdprocessedid="z0bp6t">
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>CESS(0009)</label>
                  </td>
                  <td>
                    <input v-model="model.cess_tax" @input="cessTotal"
                           class="form-control textbox txt2 number text-right ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength"
                           name="cess_tax_amt" type="number" maxlength="10" totalfire="" fdprocessedid="8vxf1">
                  </td>
                  <td>
                    <input v-model="model.cess_interest" @input="cessTotal"
                           class="form-control textbox txt2 number text-right ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength"
                           type="number" name="cess_int_amt" maxlength="10" totalfire="" fdprocessedid="3bpox">
                  </td>
                  <td>
                    <input v-model="model.cess_penalty" @input="cessTotal"
                           class="form-control textbox txt2 number text-right ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength"
                           name="cess_pen_amt" type="number" maxlength="10" totalfire=""
                           ng-disabled="isregis || challanData.roleTyp === 'OP' || challanData.role === 'AR'"
                           fdprocessedid="sp31u9">
                  </td>
                  <td>
                    <input v-model="model.cess_fees" @input="cessTotal"
                           class="form-control textbox txt2 number text-right ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength"
                           name="cess_fee_amt" type="number" maxlength="10" totalfire=""
                           ng-disabled="isregis || challanData.roleTyp === 'OP' || challanData.role === 'AR'"
                           fdprocessedid="nuvczi">
                  </td>

                  <td>
                    <input v-model="model.cess_other" @input="cessTotal"
                           class="form-control textbox txt2 number text-right ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength"
                           type="number" name="cess_oth_amt" maxlength="10" totalfire="" fdprocessedid="vwyk0m">
                  </td>
                  <td>
                    <input v-model="model.cess_total" class="form-control textbox text-right" type="number" disabled="">
                  </td>
                </tr>
                <tr>
                  <td>
                    <label title="State Goods &amp; Services Tax">Kerala <span>SGST(0006)</span><span class="ng-hide">UTGST(0007)</span></label>

                  </td>
                  <td>
                    <input v-model="model.kerala_sgst_tax" @input="sgstTotal"
                           class="form-control textbox txt3 number text-right ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength"
                           type="number" name=" sgst_tax_amt" maxlength="10" totalfire="" fdprocessedid="p1mfem">
                  </td>
                  <td>
                    <input v-model="model.kerala_sgst_interest" @input="sgstTotal"
                           class="form-control textbox txt3 number text-right ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength"
                           type="number" name=" sgst_int_amt" maxlength="10" totalfire="">
                  </td>
                  <td>
                    <input v-model="model.kerala_sgst_penalty" @input="sgstTotal"
                           class="form-control textbox txt3 number text-right ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength"
                           type="number" name=" sgst_pen_amt" maxlength="10" totalfire=""
                           ng-disabled="isregis || challanData.roleTyp === 'OP' || challanData.role === 'AR'">
                  </td>
                  <td>
                    <input v-model="model.kerala_sgst_fees" @input="sgstTotal"
                           class="form-control textbox txt3 number text-right ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength"
                           type="number">
                  </td>

                  <td>
                    <input v-model="model.kerala_sgst_other" @input="sgstTotal"
                           class="form-control textbox txt3 number text-right ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength"
                           type="number" name=" sgst_oth_amt" maxlength="10" totalfire="">
                  </td>
                  <td>
                    <input class="form-control textbox text-right" v-model="model.kerala_sgst_total" type="number"
                           disabled="" totalfire="" fdprocessedid="bl5w7r">
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <label>Total Challan Amount:</label>
                  </td>
                  <td colspan="5">
                    <span>  ₹</span>
                    <label v-if="model.total_challan_amount">{{ model.total_challan_amount }}</label>
                    <label v-else>0</label>
                    <span v-if="model.total_challan_amount"> /-</span>
                    <!---->
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <label>Total Challan Amount (In Words):</label>
                  </td>
                  <td colspan="5">
                    <label v-if="wordAmount">{{ wordAmount }}</label>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!---->
      </form>
      <div class="row">
        <div class="col-sm-4 col-xs-12">
          <h4>Payment Modes</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-sm-3 col-xs-12">
          <ul class="dev-tab" name="payMode">
            <li id="pay1" class="" @click="selectPaymentMode('e-pay')" value="EPY"><a><i class="fa fa-credit-card mr-1" aria-hidden="true"></i>
              <span>E-Payment</span><i id="pbi" class="fa fa-check fa-wd pull-right"
                               v-if="paymentMode ==='e-pay'"></i></a></li>

            <li id="pay2" value="OTC" @click="selectPaymentMode('counter')"><a><i class="fa fa-money mr-1" aria-hidden="true"></i>
              <span>Over The Counter</span><i id="pb5i" class="fa fa-check fa-wd pull-right"
                               v-if="paymentMode ==='counter'"></i></a></li>

            <li id="pay3" value="NER" @click="selectPaymentMode('neft')">
              <a>
                <i class="fa fa-globe mr-1" aria-hidden="true"></i>
                <span>NEFT/RTGS</span>
              <i id="pb55i" class="fa fa-check fa-wd pull-right"
                               v-if="paymentMode ==='neft'"></i></a></li>
          </ul>

          <!---->
          <!---->
          <span class="err"></span>
        </div>
        <!---->

        <!---->
        <!---->
        <div class="row">
          <div class="col-xs-12 text-right">
            <!---->
            <button type="button" class="btn btn-primary" title="Edit Reason" fdprocessedid="mvigxk">Edit Reason
            </button><!---->
            <button class="btn btn-default" v-if="model.total_challan_amount" type="button" title="Save"
                    @click="addItem">Save
            </button>
            <button class="btn btn-default " v-else type="button" title="Save" disabled="disabled">Save</button>
            <button type="button" class="btn btn-primary " title="Generate Challan"
                    @click="$refs.gstVerificationModal.showModal = true">Generate Challan
            </button>
          </div>
        </div>

      </div>

    </div>
    <gst-verification-modal :gstno="gstNo" ref="gstVerificationModal" @success="addItem"
                            @close="$refs.gstVerificationModal.showModal = false"></gst-verification-modal>
  </div>

</template>

<script>
import GstVerificationModal from '../views/generated-challan-page/components/GstVerificationModal';
import urls from '../data/urls';
import axios from 'secure-axios';
import store from '../store';

export default {
    name: 'PaymentChallanCreation',
    components: { GstVerificationModal },
    data () {
        return {
            wordAmount: '',
            details: '',
            fetchUrl: urls.chellanCreation.companyDetail,
            saveUrl: urls.chellanCreation.addEdit,
            conversionUrl: urls.chellanCreation.amountConversion,
            gstNo: '',
            model: {
                gst_no: '',
                challan_reason: 'Any other payment',
                bank: '',
                payment_mode: '',
                cgst_tax: '',
                cgst_interest: '',
                cgst_penalty: '',
                cgst_fees: '',
                cgst_other: '',
                cgst_total: '',
                igst_tax: '',
                igst_interest: '',
                igst_penalty: '',
                igst_fees: '',
                igst_other: '',
                igst_total: '',
                cess_tax: '',
                cess_interest: '',
                cess_penalty: '',
                cess_fees: '',
                cess_other: '',
                cess_total: '',
                kerala_sgst_tax: '',
                kerala_sgst_interest: '',
                kerala_sgst_penalty: '',
                kerala_sgst_fees: '',
                kerala_sgst_other: '',
                kerala_sgst_total: '',
                total_challan_amount: ''

            },
            paymentMode: 'e-pay'
        };
    },
    mounted () {
        this.getPaymentData();
        this.fetchInfo();
    },
    methods: {
        /* convertNulltoZero () {
            for (const item in this.model) {
                console.log('item', this.model[item]);
                if (item === 'payment_mode' || item === 'bank') {
                    console.log('items', typeof (item));
                    this.model[item] = '';
                } else if (this.model[item] === '') {
                    this.model[item] = 0;
                }
            }
            console.log('model after Conversion', this.model);
        }, */
        async paymentConvertion (amount) {
            console.log('amount to be converted', amount);
            try {
                const response = await axios.form(this.conversionUrl, { amount: amount });
                if (response.data.success === true) {
                    this.wordAmount = response.data.data;
                    console.log('converted amount', this.wordAmount);
                } else {
                    console.log('converted amount', this.response.data.error);
                }
            } catch (err) {
                console.log('Error', err);
            }
        },

        getPaymentData () {
            const paymentData = store.getters.paymentData.gst_no;
            console.log('payment Data', paymentData);
            this.model.gst_no = paymentData;
            this.gstNo = paymentData;
            console.log('payment Data', paymentData);
        },
        async addItem () {
            /* this.convertNulltoZero(); */
            console.log('addItemcalled');
            try {
                const response = await axios.form(this.saveUrl, this.model);
                const result = response.data;
                console.log('result', result);
                if (result.success === true) {
                    console.log('data', result);
                    const id = result.id;
                    console.log('ID', id);
                    await this.$router.push('/generated-challan-page/' + id + '/');
                    /* await this.$router.push({ path: '/apis/' + this.$route.params.id + '/' }); */
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        }
                    }
                    this.model.error_response = [''];
                    this.loading = false;
                }
            } catch (error) {
                console.log(error);
                this.model.error_response = [''];
                this.loading = false;
            }
        },
        async calculateTotal () {
            const total = await this.finalTotalCalculation(parseInt(this.model.cgst_total), parseInt(this.model.igst_total), parseInt(this.model.cess_total), parseInt(this.model.kerala_sgst_total), parseInt(this.model.cgst_other));
            this.model.total_challan_amount = total;
            console.log('Grandtotal: ', this.model.total_challan_amount);
            this.paymentConvertion(parseInt(this.model.total_challan_amount));
        },
        cgstTotal () {
            const total = this.finalCalculation(parseInt(this.model.cgst_tax), parseInt(this.model.cgst_interest), parseInt(this.model.cgst_penalty), parseInt(this.model.cgst_fees), parseInt(this.model.cgst_other));
            this.model.cgst_total = total;
            console.log('total', this.model.cgst_total);
            this.calculateTotal();
        },
        igstTotal () {
            const total = this.finalCalculation(parseInt(this.model.igst_tax), parseInt(this.model.igst_interest), parseInt(this.model.igst_penalty), parseInt(this.model.igst_fees), parseInt(this.model.igst_other));
            this.model.igst_total = total;
            console.log('total', this.model.igst_total);
            this.calculateTotal();
        },
        cessTotal () {
            const total = this.finalCalculation(parseInt(this.model.cess_tax), parseInt(this.model.cess_interest), parseInt(this.model.cess_penalty), parseInt(this.model.cess_fees), parseInt(this.model.cess_other));
            this.model.cess_total = total;
            this.calculateTotal();
        },
        sgstTotal () {
            const total = this.finalCalculation(parseInt(this.model.kerala_sgst_tax), parseInt(this.model.kerala_sgst_interest), parseInt(this.model.kerala_sgst_penalty), parseInt(this.model.kerala_sgst_fees), parseInt(this.model.kerala_sgst_other));
            this.model.kerala_sgst_total = total;
            this.calculateTotal();
        },
        async fetchInfo () {
            console.log('fetchFunctionCalled');
            try {
                const response = await axios.form(this.fetchUrl, { gst_no: this.gstNo });

                const result = response.data;
                this.details = result.data;
                console.log('result', result);
                if (this.details) {

                    /* await this.$router.push({ path: '/project-list/' }); */
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            console.log(errorMessage);
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },

        finalCalculation (tax, interest, penalty, fees, others) {
            if (!tax) {
                tax = 0;
            }
            if (!interest) {
                interest = 0;
            }
            if (!penalty) {
                penalty = 0;
            }
            if (!fees) {
                fees = 0;
            }
            if (!others) {
                others = 0;
            }
            const total = tax + interest + penalty + fees + others;
            /* console.log('total', total); */
            return total;
        },
        finalTotalCalculation (cgst, igst, cess, sgst) {
            if (!cgst) {
                cgst = 0;
            }
            if (!igst) {
                igst = 0;
            }
            if (!cess) {
                cess = 0;
            }
            if (!sgst) {
                sgst = 0;
            }

            const total = cgst + igst + cess + sgst;
            console.log('Ctotal', total);
            return total;
        },
        selectPaymentMode (value) {
            this.paymentMode = value;
        }
    }

};
</script>

<style scoped>
.mr-1{
  margin-right: 0.5rem !important;
}
</style>
