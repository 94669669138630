<template>
    <div class="row" data-ng-controller="transctrl" data-ng-init="init('payment')">
        <div class="col-xs-10">
            <div data-breadcrumb="" data-path="//www.gst.gov.in/" data-name="Home">
                <ol class="breadcrumb" data-ng-controller="crumbCtrl">
                    <li><a target="" href="/home/" data-ng-bind="name">Home</a></li><!---->
                    <li data-ng-repeat="breadcrumb in breadcrumbs.getAll()">
                        <ng-switch on="$last"> <!----> <!----><span ng-switch-default=""><a
                                href="/payment/quick-links/payments" data-ng-bind="breadcrumb.n"
                                target="_self">Payment</a></span>
                            <!----> </ng-switch>
                    </li><!---->
                    <li data-ng-repeat="breadcrumb in breadcrumbs.getAll()">
                        <ng-switch on="$last"> <!----><span ng-switch-when="true">Create Challan</span><!---->
                            <!----> </ng-switch>
                    </li><!----></ol>
            </div>
        </div>
        <div class="col-xs-2">
            <div class="lang dropdown">
                <span class="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                <ul class="dropdown-menu lang-dpdwn">
                    <!---->
                    <li data-ng-repeat="language in languages">
                        <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
                    </li><!---->
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'Breadcrumb'
};
</script>

<style scoped>

</style>
