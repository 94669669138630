<template>
    <div>
        <div class="content-wrapper">
            <div class="dimmer-holder" style="display: none;">
                <div id="dimmer"></div>
            </div>
            <div class="container">
                <div class="mypage">
                    <div class="row">
                        <div class="col-xs-10">
                            <div data-breadcrumb="" data-path="#" data-name="Home">
                                <ol class="breadcrumb">
                                    <li><a target="" href="/home/">Home</a></li><!---->
                                </ol>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="lang dropdown">
                                <span class="dropdown-toggle" data-toggle="dropdown">English</span>
                                <ul class="dropdown-menu lang-dpdwn">
                                    <!---->
                                    <li>
                                        <a>English</a>
                                    </li><!---->
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!---->
                    <div>

                        <div class="tabpane">
                            <div class="row">
                                <div class="col-sm-8">
                                    <h4>Reason For Challan</h4>
                                </div>
                                <div class="col-sm-4 text-right">
                                    <button type="button" class="btn btn-primary btn-sm margin-0" data-toggle="modal"
                                            data-target="#helpModal" title="HELP">
                                        HELP <i class="fa fa-question-circle" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <hr>
                            <p class="mand-text">indicates mandatory fields</p>

                            <div class="row">
                                <!--Radio Button-->
                                <div class="col-md-3 text-right"><strong class="m-cir">Reason For
                                    Challan</strong> :
                                </div>
                                <div class="col-md-4 col-sm-12 col-xs-12">
                                    <input type="radio" id="qrmp" name="type"
                                           class="form-radio ng-valid ng-not-empty ng-dirty ng-touched"
                                           value="QRMP" v-model="reason">
                                    <label for="qrmp" class="reg">Monthly payment for quarterly
                                        return</label>
                                    <br>
                                    <input type="radio" id="aop" name="type"
                                           class="form-radio ng-valid ng-not-empty ng-dirty ng-valid-parse ng-touched"
                                           value="AOP" v-model="reason">
                                    <label for="aop" class="reg">Any other payment</label>
                                </div>
                            </div>
                            <!---->

                            <!---->

                            <!---->

                            <div class="row">
                                <div class="col-xs-6 text-left">
                                    <!---->
                                </div>
                                <div class="col-xs-6 text-right">
                                    <button class="btn btn-primary" type="submit"
                                            @click="$router.push('/payment/challan-creation/')" title="Proceed" :disabled="disableState">Proceed
                                    </button>
                                </div>
                            </div>
                            <!---->

                            <div class="row" style="margin-top: 20px;">
                                <div class="col-xs-12">
                                    <p><b>Note</b>:
                                        <span>For taxpayer filing GSTR-3B on quarterly basis:</span>
                                    </p>
                                    <p>1. To make payment for the first (M1) and second (M2) months
                                        of the quarter, please select reason as ‘Monthly Payment for Quarterly Return’
                                        and the relevant
                                        period (financial year, month) and choose whether to pay through 35% challan or
                                        self-assessment
                                        challan. </p>
                                    <p>2. To make payment for the third month of the Quarter (M3),
                                        please use ‘Create Challan’ option in payment Table-6 of Form GSTR-3B Quarterly.
                                        An auto-populated
                                        challan amounting to liabilities for the quarter net off credit utilization and
                                        existing cash
                                        balance can be generated and used to offset liabilities. </p>
                                    <div>
                                        <a href="#">Click here</a> for
                                        navigation to ‘Return Dashboard’ and prepare GSTR-3B Quarterly. Filing of
                                        GSTR-3B Quarterly
                                        available in the third month of the quarter is mandatory.
                                    </div>

                                    <div><b>*</b>For adding cash to Electronic Cash Ledger, already established
                                        procedure may be followed.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!---->

                        <!-- Popup modal to display cash balance minor head wise details -->
                        <div class="modal fade bs-example-modal-md" id="balanceModal" tabindex="-1" role="dialog"
                             aria-labelledby="myModalLabel">
                            <div class="modal-dialog modal-lg" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal"
                                                aria-label="Close"><span
                                                aria-hidden="true">×</span></button>
                                        <h4>
                                            Cash Balance as on 29-06-2024
                                        </h4>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <h4>Integrated Tax (₹)</h4>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="row">
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Tax</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Interest</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Penalty</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Fee</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Others</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Total</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <h4>Central Tax (₹)</h4>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="row">
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Tax</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Interest</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Penalty</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Fee</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Others</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Total</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <!----><h4>State Tax (₹)</h4>
                                                <!---->
                                                <!---->
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="row">
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Tax</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Interest</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Penalty</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Fee</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Others</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Total</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <h4>CESS (₹)</h4>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="row">
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Tax</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Interest</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Penalty</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Fee</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Others</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="reg">Total</label>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <label class="reg"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-primary" data-dismiss="modal">OK
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Popup modal to display help content -->
                        <div id="helpModal" class="modal fade" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal">×</button>
                                        <h4 class="modal-title">Monthly tax payments under QRMP
                                            scheme</h4>
                                    </div>
                                    <div class="modal-body">
                                        <span>The reason for challan can be selected from the Reason for Challan page (Services -&gt; Payments -&gt; Create Challan). This page displays reason as Monthly payment for quarterly return and Any other payment.</span>
                                        <ul>
                                            <li>For taxpayer filing GSTR-3B on quarterly basis and
                                                intending to make payment for first and second months of the quarter,
                                                please select reason as
                                                Monthly Payment for quarterly return.
                                            </li>
                                            <li>Please select the Financial year and period from the
                                                dropdown and select challan type as 35%challan or Challan on
                                                self-assessment basis.
                                            </li>
                                        </ul>
                                        <span>35% Challan</span>
                                        <ul>
                                            <li>35% of the tax liability paid from cash ledger in GSTR-3B
                                                for the preceding quarter where the return is furnished quarterly shall
                                                be auto-populated which
                                                needs to be paid.
                                            </li>
                                            <li>100% of the tax liability paid from cash ledger in
                                                GSTR-3B return for the preceding month where the return is furnished
                                                monthly shall be
                                                auto-populated which needs to be paid.
                                            </li>
                                            <li>Please note that when taxpayer exercises 35% challan
                                                option, No interest shall be levied for the selected month if payment is
                                                made by 25th of the
                                                next month or the extended date, if any.
                                            </li>
                                        </ul>
                                        <span>Challan on Self-Assessment Basis</span>
                                        <ul>
                                            <li>Taxpayer can self-assess his current month's liability
                                                (net of Input Tax credit) and shall generate challan for the same.
                                            </li>
                                            <li>Interest will be levied on payment made through 'Challan
                                                on self-assessment basis' (other than 35% challan) in case of delayed
                                                payment (after due date of
                                                25th of next month or the extended date, if any) or short payment.
                                            </li>
                                        </ul>
                                        <b>Note:</b>&nbsp;<span>For taxpayers whose AATO is greater than ₹5Cr., the filing preference shall be monthly only, and they will not be able to generate challan with 'Monthly payment for quarterly return' reason.</span>
                                    </div>
                                    <div class="modal-footer" style="text-align: center;">
                                        <button type="button" class="btn btn-primary margin-0" data-dismiss="modal"
                                        >close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!--        <button @click="setPaymentInfo">Payment Data</button>-->
    </div>
</template>

<script>
// import store from '@/store';
import { mapGetters } from 'vuex';
export default {
    name: 'ReasonForChallan',
    mounted () {
        // const paymentData = store.getters.paymentData;
        console.log('payment-data', this.paymentData);
    },
    computed: {
        ...mapGetters(['paymentData'])
    },
    watch: {
        reason (newVal, oldVal) {
            console.log(newVal, oldVal);
            this.checkDisableState();
        }
    },
    data () {
        return {
            reason: '',
            disableState: true
        };
    },
    methods: {
        checkDisableState () {
            if (this.reason === 'AOP') {
                this.disableState = false;
            } else {
                this.disableState = true;
            }
        },
        setPaymentInfo () {
            const paymentInfo = this.paymentData;
            console.log('payment-info', paymentInfo);
        }
    }
};
</script>

<style scoped>

</style>
