<template>
  <div>
    <div class="row">
      <div class="col-xs-10">
        <div data-breadcrumb="" data-path="//www.gst.gov.in/" data-name="Home">
          <ol class="breadcrumb">
            <li><a target="" href="/home/">Home</a></li>
          </ol>
        </div>
      </div>
    </div>
    <div class="content-pane" style="min-height: 514.2px;">
      <div class="row">
        <div class="col-xs-12">
          <ol class="breadcrumb">
            <li><a href="/home/">Home</a></li>
            <li><a target="_self" href="#">Services</a>
            </li>
            <li><span>Payments</span></li>
          </ol>
        </div>
      </div>
      <div class="content-pane" style="min-height: 514.2px;">
        <div class="row">
          <div class="col-xs-12">
            <h4>Payments Quick Links</h4>
            <ul>
              <li>
                <a href="/challan-create/">Create Challan</a>
              </li>
              <li>
                <a href="#">Track Payment Status</a>
              </li>
              <li>
                <a target="_self" href="#">
                  Grievance against Payment(GST PMT-07)
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'HomePage'
};
</script>
