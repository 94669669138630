<template>
    <div>
        <div class="row">
            <div class="col-sm-4 col-xs-12">
                <h4 data-ng-bind="trans.HEAD_TP_DETL">Details of Taxpayer</h4>
            </div>
        </div>
        <div class="tbl-format">
            <div class="row">
                <div class="inner">
                    <div class="col-sm-4 col-xs-12 ">
                        <p data-ng-bind="trans.LBL_GSTIN">GSTIN/Other Id</p>
                        <span><strong data-ng-bind="challanData.gstin">{{taxpayer.gst_no}}</strong></span>
                    </div>
                    <div class="col-sm-4 col-xs-12 ">
                        <p data-ng-bind="trans.LBL_EMAIL">Email Address</p>
                        <span>
                        <strong style="word-wrap:break-word;"
                                data-ng-bind="challanData.email_id">{{taxpayer.email}}</strong>
                    </span>
                    </div>
                    <div class="col-sm-4 col-xs-12 ">
                        <p data-ng-bind="trans.LBL_MOBNUM">Mobile Number</p>
                        <span><strong data-ng-bind="challanData.mobile_num">{{taxpayer.mobile_number}}</strong></span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="inner">
                    <div class="col-sm-4 col-xs-12 ">
                        <p data-ng-bind="trans.LBL_NAME">Name</p>
                        <span><strong data-ng-bind="challanData.reg_nam">{{taxpayer.name}}</strong></span>
                    </div>
                    <div class="col-sm-4 col-xs-12 ">
                        <p data-ng-bind="trans.LBL_ADDR">Address</p>
                        <span><strong data-ng-bind="challanData.address">{{taxpayer.address}}</strong></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DetailsOfTaxPayer',
    props: {
        details: {
            type: Object
        },
        taxpayer: {
            type: Object
        }
    }
};
</script>

<style scoped>

</style>
