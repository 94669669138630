export default {
    state: {
        paymentData: {},
        jsonFormat: {},
        checkedStatus: false,
        needChecking: true
    },

    getters: {
        paymentData: state => state.paymentData,
        jsonFormat: state => state.jsonFormat
    },

    mutations: {
        setPayment (state, payload) {
            console.log('payload', payload);
            state.paymentData = payload;
        },
        setJsonFormat (state, payload) {
            console.log('payload', payload);
            state.jsonFormat = payload;
        }
    },

    actions: {
        setPayment ({ commit }, payload) {
            commit('setPayment', payload);
        },

        setJsonFormat ({ commit }, payload) {
            commit('setJsonFormat', payload);
        }
    }
};
