<template>
  <div>
    <header class="main-header">
      <div class="skip">
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <ul class="skip list-inline">
                <li><a tabindex="-1" class="accessible" href="#">Skip to Main Content</a></li>
                <li class="high-low"><i class="fa fa-adjust"></i></li>
                <li class="fresize f-up">A<sup>+</sup></li>
                <li class="fresize f-down">A<sup>-</sup></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row branding">
          <div class="col-xs-12">
            <a href="#"
               class="menuList"
            >
              <img alt="Goods and Services Tax Home"
                   style="vertical-align:middle; position:relative;top:-5px;left: 12px;width: 62px;height: 72px;"
                   src="//static.gst.gov.in/uiassets/images/Emblem_of_India-white.svg">
            </a>
            <h1 class="site-title">
              <a href="#">
                Goods and Services Tax
                <br>
                <span class="subtitle">Government of India, States and Union Territories</span>
              </a>
            </h1>
            <ul class="list-inline mlinks">
              <!---->
              <li>
                <a target="_self" href="#" class="button"> Register</a>
              </li>
              <!---->
              <!---->
              <li>
                <a target="_self" href="#" class="button"> Login</a>
              </li>
              <!---->
              <!---->
              <!---->
            </ul>
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#main">
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="sr-only">Toggle navigation</span>
            </button>
          </div>
        </div>
      </div>
    </header>

    <nav class="navbar navbar-default">
      <div class="container">
        <div id="main" class="navbar-collapse collapse">
          <ul class="nav navbar-nav">
            <li class="menuList">
              <a class="nav_home" href="#">Home</a>
            </li>
            <!-- CR#22679 Enabling changes -->
            <li class="dropdown drpdwn menuList">
              <a
                  data-toggle="dropdown" role="button" aria-expanded="false" target="_self" href="#"
                  class="dropdown-toggle">Services <span class="caret"></span></a>
            </li>
            <li class="dropdown drpdwn menuList"
            >
              <a
                  href="#">GST Law </a>
            </li>
            <li class="has-sub drpdwn menuList">
              <a data-toggle="dropdown" role="button" aria-expanded="false" target="_self" href="#"
                 class="dropdown-toggle">
                Downloads <span class="caret"></span>
              </a>
            </li>
            <li class="dropdown drpdwn menuList">
              <a data-toggle="dropdown" role="button" aria-expanded="false" target="_self" href="#"
                 class="dropdown-toggle">Search Taxpayer <span class="caret"></span></a>
            </li>
            <li class="menuList">
              <a role="button" aria-expanded="false"
                 target="_self" href="#">Help
                and Taxpayer Facilities</a>
            </li>
            <li class="dropdown drpdwn menuList"
            >
              <a
                  href="#">e-Invoice</a>
            </li>
            <li class="dropdown drpdwn menuList">
              <a href="#">News and Updates</a>
            </li>
            <li class="mnav"><a href="#"> Register</a></li>
            <li class="mnav"><a href="#">Login</a></li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
    name: 'Header'
};
</script>

<style scoped>

</style>
