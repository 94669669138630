import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '@/components/HomePage.vue';
import CreateChallan from '@/components/CreateChallan.vue';
import CreateChallanOne from './components/CreateChallanOne';
import ReasonForChallan from '@/components/ReasonForChallan';
import PaymentChallanCreation from '@/components/PaymentChallanCreation';
import GeneratedChallanPage from './views/generated-challan-page/GeneratedChallanPage';
import CreateChallanJsonView from './views/CreateChallanJsonResponsePage';

Vue.use(VueRouter);

const routes = [
    { path: '/home/', name: 'HomePage', component: HomePage },
    { path: '/create-challan/', name: 'CreateChallan', component: CreateChallan },
    { path: '/challan-create/', name: 'CreateChallanOne', component: CreateChallanOne },
    { path: '/challan-reason/', name: 'ReasonForChallan', component: ReasonForChallan },
    { path: '/payment/challan-creation/', name: 'PaymentChallanCreation', component: PaymentChallanCreation },
    { path: '/generated-challan-page/:id/', name: 'CreateChallanPage', component: GeneratedChallanPage },
    { path: '/create-challan-json-view/', name: 'CreateChallanJsonView', component: CreateChallanJsonView },
    { path: '*', redirect: '/home/' }

];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;
